import { TOOL_URL } from '../constants';
import axios from 'axios';

const getHeaders = { withCredentials: true };
const postHeaders = { withCredentials: true, 'content-type': 'multipart/form-data' };

const basicResponseParser = response => response;

const safeCall = async (func, responseParser = basicResponseParser) => {
    let data, error;
    try {
        const response = await func();
        data = responseParser(response);
      //  console.log({ response, data });
    } catch (err) {
        error = err;
    }

    // TODO: Parse error from response

    return { data, error };
};

const getEndpoint = (path) => `${TOOL_URL}/${path}`;

export const get = async (path, responseParser) => {
    const endpoint = getEndpoint(path);
    const request = async () => await axios.get(endpoint, getHeaders);
    return await safeCall(request, responseParser);
};

export const post = async (path, data, responseParser) => {
    const endpoint = getEndpoint(path);
    const request = async () => await axios.post(endpoint, data, postHeaders);
    return await safeCall(request, responseParser);
};