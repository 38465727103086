import React, { useState, useEffect } from 'react';
import useApi from '../api/useApi';

const MessagesContext = React.createContext();

const MessagesProvider = ({ children }) => {
  const [inbox, setInbox] = useState([]);
  const [users, setUsers] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [sent, setSent] = useState([]);
  const [drafts, setDrafts] = useState([]);

  const {
    createMessage, sendMessage, fetchMessages, fetchRecipients
  } = useApi();

  // TODO: Wait for session?
  const fetchAndSetMessages = async () => {
    const { data: messages, error: messagesError } = await fetchMessages();
  //  setInbox(messages);
    setInbox([]);
      
    const { data: recipients, error: recipientsError } = await fetchRecipients();
    const { users: receivedUsers = [], suppliers: receivedSuppliers = [] } = recipients ?? {};
    setUsers(receivedUsers);
    setSuppliers(receivedSuppliers);
  }

  useEffect(() => {
    fetchAndSetMessages();
  }, []);

  return (
    <MessagesContext.Provider value={{ inbox, users, suppliers, createMessage, sendMessage }} >
      {children}
    </MessagesContext.Provider>
  );
};

export { MessagesContext };
export default MessagesProvider;