import { get, post } from '../utils';

export const fetchProfile = async () => {
    const path = 'users/getUserInfo';
    const parseResponse = response => response?.data;
    return await get(path, parseResponse);
};

export const updateProfile = async (updates) => {
    const path = 'users/editUserInfo';
    const parseResponse = response => response?.data?.type === 'success';
    return await post(path, updates, parseResponse);
};

export const getProfileImage = async (userId) => {
    const path = 'users/getProfileImage/';
    const data = new FormData();
    data.append('userId', userId);
    return await post(path, data);
};

export const uploadProfileImage = async (image) => {
    const path = 'users/uploadProfileImage/';
    const data = new FormData();
    data.append('data', image);
    return await post(path, data);
};