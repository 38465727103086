import { post } from '../utils';

export const postLogin = async (username, password) => {
    const path = 'login/auth/';
    const data = new FormData();
    data.append('username', username);
    data.append('password', password);
    const parseResponse = response => {
        const { data: { error } } = response;
        return !error;
    };
    return await post(path, data, parseResponse);
};

export const postLogout = async () => {
    const path = 'login/logout/';
    // TODO: Add responseparser
    return await post(path);
};

export const getSessionStatus = async () => {
    const path = 'login/getSessionTokenState/';
    // TODO: Add responseparser
    const parseResponse = response => {
        const { data: { authenticated } } = response;
        return authenticated;
    };
    return await post(path, null, parseResponse);
};

