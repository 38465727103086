import React, { useState, useEffect } from 'react';
import translations from './locales';
import { saveLocale, getLocale } from '../utils/localstorageHelper';

const DEFAULT_LOCALE = 'en';

const TranslationContext = React.createContext();

const TranslationProvider = ({ children }) => {
  const [locale, setLocale] = useState(DEFAULT_LOCALE);

  const availableLocales = Object.keys(translations);

  const changeLocale = (newLocale) => {
      if (availableLocales.includes(newLocale)) {
          setLocale(newLocale);
          saveLocale(newLocale)
      }
  };

  const translate = key => translations?.[locale]?.[key] ?? "";

  useEffect(() => {
    const savedLocale = getLocale(DEFAULT_LOCALE);
    if (savedLocale) {
      setLocale(savedLocale);
    }
  }, []);

  return (
    <TranslationContext.Provider value={{ locale, changeLocale, translate, availableLocales }} >
      {children}
    </TranslationContext.Provider>
  );
};

export { TranslationContext };
export default TranslationProvider;