import React, { useState, useEffect, useContext } from "react";
import useApi from "../api/useApi";
import { TranslationContext } from "../translation/translationContext";
import logo from "../assets/images/logo.svg";

const EventsContext = React.createContext();

const EventsProvider = ({ children }) => {
  const { translate } = useContext(TranslationContext);
  //const [events, setEvents] = useState([]);

  // const { fetchEvents, joinEvent } = useApi();

  const events = [
    {
      id: "workshop_2024_09_25",
      date: new Date("2024-09-25"),
      title: translate("workshop"),
      text: translate("workshopPresentation"),
      image: logo,
      url: "https://firebasestorage.googleapis.com/v0/b/vote-games.appspot.com/o/ChemAct%20presentation%2025%20september%202024.pdf?alt=media&token=fd662b5f-db28-4aa3-9269-4d23c74aa683",
    },
    {
      id: "workshop_2024_04_16",
      date: new Date("2024-04-16"),
      title: translate("workshop"),
      text: translate("workshopPresentation"),
      image: logo,
      url: "https://firebasestorage.googleapis.com/v0/b/vote-games.appspot.com/o/Presentation%20ChemAct%20Stefan%20Posner%2016%20april%202024.pdf?alt=media&token=ce74bfda-ed43-4fe7-8e2f-ce26812dec2c",
    },
    {
      id: "workshop_2024_01_24",
      date: new Date("2022-01-24"),
      title: translate("workshop"),
      text: translate("workshopPresentation"),
      image: logo,
      url: "https://firebasestorage.googleapis.com/v0/b/vote-games.appspot.com/o/Presentation%20Stefan%20Posner%20ChemAct%2024%20Januari%202024.pdf?alt=media&token=cc97bf75-77b5-4b46-b170-793983539b4c",
    },
    {
      id: "workshop_2023_03_07",
      date: new Date("2023-03-07"),
      title: translate("workshop"),
      text: translate("workshopAgenda"),
      image: logo,
      url: "https://firebasestorage.googleapis.com/v0/b/vote-games.appspot.com/o/Agenda%20ChemAct%20workshop%20230322.pdf?alt=media&token=7cb19712-a5c8-4718-bfbf-1bfe923a59a3",
      extraUrl:
        "https://firebasestorage.googleapis.com/v0/b/vote-games.appspot.com/o/ChemaAct%20presentation%2022%20mars%202023.pdf?alt=media&token=60a7fe59-c6e2-4492-9c2d-c0fab4b3cbb7",
      extraUrlText: translate("workshopPresentation"),
      additionalUrl:
        "https://firebasestorage.googleapis.com/v0/b/vote-games.appspot.com/o/Printprojektmo%CC%88te%20230307.pdf?alt=media&token=e3399b2c-2e6e-48cc-b528-2654b42aecb4",
      additionalUrlText: translate("workshopSpreadsheet"),
    },
    {
      id: "workshop_2022_09_28",
      date: new Date("2022-09-28"),
      title: translate("workshop"),
      text: translate("workshopPresentation"),
      image: logo,
      url: "https://firebasestorage.googleapis.com/v0/b/vote-games.appspot.com/o/Presentation%20ChemAct%20workshop%2028%20september%202022.pdf?alt=media&token=275bfdc5-0ca1-4eee-bb05-79248eed0dfe",
    },
    {
      id: "member_meeting_2022_06_01",
      date: new Date("2022-06-01"),
      title: translate("memberMeeting"),
      text: translate("memberMeetingPresentation"),
      image: logo,
      url: "https://firebasestorage.googleapis.com/v0/b/vote-games.appspot.com/o/Presentation%20medlemsmo%CC%88te%201%20juni%202022.pdf?alt=media&token=66151151-360d-4b23-a2b5-6c1cc962b3a4",
      extraUrl:
        "https://firebasestorage.googleapis.com/v0/b/vote-games.appspot.com/o/Fact%20Sheet%20Membranes.pdf?alt=media&token=e9525c6c-74d0-4788-b731-ebf1b6d99389",
      extraUrlText: translate("factSheetMembranes"),
    },
    {
      id: "member_meeting_2022_02_02",
      date: new Date("2022-02-02"),
      title: translate("memberMeeting"),
      text: translate("memberMeetingPresentation"),
      image: logo,
      url: "https://firebasestorage.googleapis.com/v0/b/vote-games.appspot.com/o/Presentation%20medlemsmo%CC%88te%202%20mars%202022.pdf?alt=media&token=6d9250f0-19ae-4ea6-9e01-d04dd1178bd9",
      extraUrl:
        "https://firebasestorage.googleapis.com/v0/b/vote-games.appspot.com/o/fo%CC%88rklaringar%20till%20begrepp%20bild%208%20medlemsmo%CC%88te%202%20mars%202022.pdf?alt=media&token=2dc45d38-cd44-4b80-91e5-a1e4a82c3631",
      extraUrlText: translate("explanations"),
    },
    {
      id: "member_meeting_2021_12_07",
      date: new Date("2021-12-07"),
      title: translate("memberMeeting"),
      text: translate("memberMeetingPresentation"),
      image: logo,
      url: "https://firebasestorage.googleapis.com/v0/b/vote-games.appspot.com/o/ChemAct%20medlemsmo%CC%88te%207%20december%202021.pdf?alt=media&token=6968a489-4df1-4e05-a08f-d86a6ccde513",
    },
    {
      id: "member_meeting_2021_10_09",
      date: new Date("2021-10-09"),
      title: "Member meeting",
      text: "Presentation from member meeting",
      image: logo,
      url: "https://firebasestorage.googleapis.com/v0/b/vote-games.appspot.com/o/ChemAct%20medlemsmo%CC%88te%209%20oktober%202021.pdf?alt=media&token=b01f52d6-a4a1-4bb5-a911-00dd775dd7ee",
    },
  ];

  // TODO: Wait for session?
  // const fetchAndSetEvents = async () => {
  //     const fetchedEvents = await fetchEvents();
  //     setEvents(fetchedEvents);
  // }

  // const join = (eventId) => {
  //   // TODO
  // };

  // useEffect(() => {
  //   fetchAndSetEvents();
  // }, []);

  return (
    <EventsContext.Provider value={{ events }}>
      {children}
    </EventsContext.Provider>
  );
};

export { EventsContext };
export default EventsProvider;
