import React from 'react';
import SessionProvider from './src/session/sessionContext';
import NewsProvider from './src/news/newsContext';
import EventsProvider from './src/events/eventsContext';
import MessagesProvider from './src/messages/messagesContext';
import ProfileProvider from './src/profile/profileContext';
import TranslationProvider from './src/translation/translationContext';

export const wrapRootElement = ({ element }) => (
  <SessionProvider>
    <TranslationProvider>
      <NewsProvider>
        <EventsProvider>
          <MessagesProvider>
            <ProfileProvider>
              {element}
            </ProfileProvider>
          </MessagesProvider>
        </EventsProvider>
      </NewsProvider>
    </TranslationProvider>
  </SessionProvider>
);