import React, { useState, useEffect } from 'react';
import useApi from '../api/useApi';

const NewsContext = React.createContext();

const NewsProvider = ({ children }) => {
  const [news, setNews] = useState([]);

  const { fetchNews, bookmarkArticle } = useApi();

  const fetchAndSetNews = async () => {
      const fetchedNews = await fetchNews();
      setNews(fetchedNews);
  };

  const bookmark = (articleId) => {
    // TODO
  };

  // TODO: Wait for session?
  useEffect(() => {
    fetchAndSetNews();
  }, []);

  return (
    <NewsContext.Provider value={{ news, bookmark }} >
      {children}
    </NewsContext.Provider>
  );
};

export { NewsContext };
export default NewsProvider;