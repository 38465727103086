import React, { useState, useEffect, useContext } from 'react';
import useApi from '../api/useApi';
import { SessionContext } from '../session/sessionContext';
import { SUPPLIER_ROLE, TOOL_URL } from '../constants';
import { isBrowser } from '../utils/globalFunctions';

const ProfileContext = React.createContext();

const ProfileProvider = ({ children }) => {
  const [profile, setProfile] = useState();
  const [profileImage, setProfileImage] = useState([]);

  const { isLoggedIn } = useContext(SessionContext);

  const { fetchProfile, updateProfile, getProfileImage, uploadProfileImage } = useApi();

  const ALLOWED_KEYS = ['name', 'title', 'email', 'phoneNumber', 'country', 'profileImage', 'hasSeenWelcomeMessage' ];

  const fetchAndSetProfile = async () => {
      const { data: fetchedProfile, error: fetchError } = await fetchProfile();
      setProfile(fetchedProfile);

      const { role } = fetchedProfile;

      if (role === SUPPLIER_ROLE && isBrowser()) {
        window.location.href = TOOL_URL;
      }
  };

  const fetchAndSetProfileImage = async () => {
    const { data, error } = await getProfileImage(profile?.id);
    // setProfileImage(data); // TODO
  };

  // TODO: Error handling (errorContext)
  const editProfile = async (updateData) => {
    const formData = new FormData();

    ALLOWED_KEYS.forEach((key) => {
      const value = updateData[key] ?? profile[key] ?? '';
      formData.append(key, value);
    });

    const { data: isSuccess, error: updateError } = await updateProfile(formData);

    if (isSuccess) {
      const updatedProfile = ALLOWED_KEYS.reduce((result, key) => ({
          ...result,
          [key]: updateData[key] ?? profile[key] ?? ''
      }), profile);
      setProfile(updatedProfile);
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      fetchAndSetProfile();
    }
  }, [isLoggedIn]);

  // useEffect(() => {
  //   if (profile) {
  //     fetchAndSetProfileImage()
  //   }
  // }, [profile]);

  return (
    <ProfileContext.Provider value={{ profile, editProfile, fetchAndSetProfile, uploadProfileImage }} >
      {children}
    </ProfileContext.Provider>
  );
};

export { ProfileContext };
export default ProfileProvider;