import { get, post } from '../utils';

export const createMessage = async ({ to, subject, body, appendix }) => {
    const path = 'messages/createMessage/';
    const data = new FormData();
    data.append('to', to);
    data.append('subject', subject);
    data.append('body', body);
    data.append('appendix', appendix);
    return await post(path, data);
};

export const sendMessage = async ({ to, subject, body, appendix }) => {
    const path = 'messages/sendMessage/';
    const data = new FormData();
    data.append('to', to);
    data.append('subject', subject);
    data.append('body', body);
    data.append('appendix', appendix);
    return await post(path, data);
};

export const markMessageRead = async (messageId) => {
    const path = 'messages/markMessageRead/';
    const data = new FormData();
    data.append('id', messageId);
    return await post(path, data);
};

export const archiveMessage = async (messageId) => {
    const path = 'messages/archiveMessage/';
    const data = new FormData();
    data.append('id', messageId);
    return await post(path, data);
};

export const deleteMessage = async (messageId) => {
    const path = 'messages/deleteMessage/';
    const data = new FormData();
    data.append('id', messageId);
    return await post(path, data);
};

export const fetchMessages = async () => {
    const path = 'messages/fetchMessages/';
    const parseResponse = response => {
        const messages = response?.data ?? [];
        return messages;
    };
    return await get(path, parseResponse);
};

export const fetchRecipients = async () => {
    const path = 'messages/fetchRecipients/';
    const parseResponse = response => {
        const { users = [], suppliers = [] } = response?.data ?? {};
        return { users, suppliers };
    };
    return await get(path, parseResponse);
};