import {
  TURNOVER_LIMIT,
  LOWER_YEARLY_COST,
  HIGHER_YEARLY_COST,
} from "../../constants";

const faqs = {
  becomeMember: {
    question: "How do I become a member?",
    answer:
      "Fill in this form and we will get in touch with you as soon as possible.",
    link: "/#contacts",
  },
  fees: {
    question: "How much are the fees?",
    answer: "See fees here",
    link: "/about#fees",
  },
  payment: {
    question: "What payment options do you have?",
    answer: "Invoice 30 days net.",
  },
  support: {
    question: "What support can you provide?",
    answer:
      "As a member of the ChemAct network you will be constantly updated about risk chemicals in terms of frequent contact with our support team and through our workshops.",
  },
  inPerson: {
    question: "Can I meet you in person?",
    answer: "We will meet in person at our workshops twice per year.",
  },
};

export default {
  faqs,
  home: "HOME",
  aboutUs: "ABOUT US",
  contact: "CONTACT",
  faq: "FAQ",
  login: "LOGIN",
  news: "NEWS",
  events: "EVENTS",
  library: "LIBRARY",
  support: "SUPPORT",
  logout: "SIGN OUT",
  slogan:
    "Supports you to identify risk and hazardous chemicals and manage the legal requirements for your products",
  contactTitle: "GET IN TOUCH",
  name: "Name",
  companyName: "Company name",
  contactNumber: "Contact number",
  contactEmail: "Contact email",
  companyTurnover: "Company turnover",
  upTo: "Up to",
  above: "Above",
  queryDescription:
    "If you have a query, write it here. If you’re interested in becoming a member, please tick the following box",
  memberInterested: "I’m interested in becoming a member",
  send: "send",
  aboutUsTitle: "WHAT IS CHEMACT?",
  aboutUsDescription: `Identify – the ChemAct tool supports you to identify the risk and hazardous chemicals per component without the knowledge of chemistry of the user.\n\nManage – the ChemAct tool supports you to manage the legal chemical requirements for your products.\n\nSupport – as a member of the ChemAct network you will be constantly updated about risk chemicals in terms of frequent contact with our support team and through our workshops.`,
  contactUsToday: "CONTACT US TODAY",
  meetTheTeam: "MEET THE TEAM",
  anneDescription:
    "Anne Broström with 30 years of experience as buyer with production and quality responsibility for textile products, mainly within Outdoor. Worked very close to the producers mainly in China. 2007 started a new role as CSR and Environment Mgr. The experience from the previous years was very useful to support the suppliers in these processes. 2011 the start of my own company, to be able to support more companies in CSR and Environment issues and to help them to structure to meet the continuously increasing demands, as an actor within EU. Curiosity about Anne - speaks 5 languages fluently – Swedish, Finnish, English, German and Portuguese. Future aim is also to talk Chinese.",
  stefanDescription:
    "Stefan Posner is a polymer and textile chemist with over 30 years’ experience in research on chemicals in textiles and polymeric materials in cooperation with all from small (SME) to large international companies, international and national authorities and academia in several international and national projects over the years. For several years, Stefan Posner has supported both SME and large international companies with chemicals management. This is to in practice to handle their hazardous chemicals in their value chains and products without being chemists. This aims to systematically substitute identified hazards actually used in the value chain and replace them with low hazard and technical feasible alternatives in a systematic way over time.",
  lenaDescription: "TODO",
  feesMemberships: "FEES & MEMBERSHIPS",
  whatIsIncluded: "What is included in all member options",
  includedDescription: `- Support from the team\n- 24/7h login access to the tool`,
  costUpTo: `BUSINESSES UP TO ${TURNOVER_LIMIT} TURNOVER`,
  costUpToPerYear: `${LOWER_YEARLY_COST} PER YEAR`,
  costAbove: `BUSINESSES ABOVE ${TURNOVER_LIMIT} TURNOVER`,
  costAbovePerYear: `${HIGHER_YEARLY_COST} PER YEAR`,
  faqTitle: "OUR MOST COMMONLY ASKED QUESTIONS...",
  anotherQuestion: "GOT ANOTHER QUESTION? CONTACT US HERE!",
  introduction: "Introduction",
  welcomeTitle: "Welcome to Chemact!",
  welcomeText: `Thank you for your interest on ChemAct network!\n\nAs a member in ChemAct network you will receive following support:\n\n - The ChemAct tool supports you to identify the risk and hazardous chemicals per component without the knowledge of chemistry of the user.\n - The ChemAct tool supports you to manage the legal chemical requirements for your products.\n - You will be constantly updated about risk chemicals through frequent contact with our support team and through our workshops.\n\nWhat happens next?\nWe will contact you to set up a Teams meeting to introduce the ChemAct tool and the network.`,
  welcomeRegards: "Best Regards,\n\nThe ChemAct team",
  "log in": "Log in",
  email: "Email",
  password: "Password",
  forgotPassword: "Forgot password",
  loginHelp: "Help with login",
  myProfile: "My profile",
  featuredNews: "Featured news",
  eventsTitle: "Events",
  messages: "Messages",
  accessTool: "ACCESS TOOL",
  readMore: "Read more",
  title: "Title",
  phone: "Phone",
  country: "Country",
  memberSince: "Member since",
  cancel: "Cancel",
  edit: "Edit",
  notAvailable: "Not available",
  new: "new",
  signUpHere: "Sign up here",
  enterDetails: "Please enter your details below",
  inviteOther:
    "If you wish to bring colleagues or others with you, please enter their names and e-mail addresses here:",
  sendLarge: "SEND",
  libraryDescription:
    "Find and access PDF-documents related to legislations, guidelines, explanatory texts, analysis and more using our search engine.",
  searchLibrary: "Search the library...",
  foundNoArticles: "Found no matching articles",
  supportTitle: "CONTACT US",
  supportDescription:
    "Feel free to contact us at anytime. We will get back to you as soon as possible.",
  info: "Info",
  sweden: "Sweden",
  sent: "Sent",
  archive: "Archive",
  drafts: "Drafts",
  flagged: "Flagged",
  inboxTitle: "INBOX",
  sendNewMessage: "SEND NEW MESSAGE",
  subject: "Subject",
  message: "Message",
  category: "Category",
  noNews: "No news articles yet, coming soon.",
  noMatchingNews: "Found no matching news articles...",
  noEvents: "No events yet, coming soon",
  searchNews: "Search our news...",
  noMessagesTitle: "No new messages",
  noMessagesText:
    "No new messages has been received. Visit your inbox by clicking read more.",
  noNewsTitle: "No new news articles",
  noNewsText:
    "No new news articles has been published. Take a look at the news page by clicking read more.",
  noEventsTitle: "No new events",
  noEventsText:
    "No new events has been published. Take a look at the event page by clicking read more.",
  loginError: "Failed to login. Incorrect username or password.",
  mailSent:
    "Thank you for your message. We will get back to you as quickly as possible.",
  noMessages: "No received messages yet",
  username: "Username",
  memberMeeting: "Member meeting",
  memberMeetingPresentation: "Presentation from member meeting.",
  explanations: "Explanation of terms",
  factSheetMembranes: "Fact sheet - membranes",
  workshop: "Workshop",
  workshopPresentation: "Presentation from workshop",
  workshopAgenda: "Agenda for workshop",
  workshopSpreadsheet: "Spreadsheet from workshop",
};
