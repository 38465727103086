import {
  TURNOVER_LIMIT,
  LOWER_YEARLY_COST,
  HIGHER_YEARLY_COST,
} from "../../constants";

const faqs = {
  becomeMember: {
    question: "Hur blir jag medlem?",
    answer:
      "Fyll i det här formuläret så kontaktar vi dig så snart som möjligt.",
    link: "/#contacts",
  },
  fees: {
    question: "Hur mycket kostar ett medlemsskap?",
    answer: "Se avgifter här",
    link: "/about#fees",
  },
  payment: {
    question: "Vilka betalmöjligheter erbjuder ni?",
    answer: "30 dagars faktura.",
  },
  support: {
    question: "Vilken typ av support erbjuder ni?",
    answer:
      "Som medlem blir du kontinuerligt uppdaterad om riskabla kemikalier genom frekvent kontakt med vårat support-team och genom våra workshops.",
  },
  inPerson: {
    question: "Kan jag träffa er fysiskt?",
    answer: "Vi träffas på våra workshops två gånger per år.",
  },
};
export default {
  faqs,
  home: "HEM",
  aboutUs: "OM OSS",
  contact: "KONTAKT",
  faq: "FAQ",
  login: "LOGGA IN",
  news: "NYHETER",
  events: "EVENEMANG",
  library: "BIBLIOTEK",
  support: "SUPPORT",
  logout: "LOGGA UT",
  slogan:
    "Hjälper dig att identifiera risk och farliga kemikalier och hantera juridiska krav på dina produkter",
  contactTitle: "KONTAKTA OSS",
  name: "Namn",
  companyName: "Företagsnamn",
  contactNumber: "Telefonnummer",
  contactEmail: "Email",
  companyTurnover: "Företagsomsättning",
  upTo: "Upp till",
  above: "Över",
  queryDescription:
    "Om du har en fråga, skriv den här. Om du är intresserad av att bli medlem, vänligen fyll i denna ruta",
  memberInterested: "Jag är intresserad av att bli medlem",
  send: "Skicka",
  aboutUsTitle: "VAD ÄR CHEMACT?",
  aboutUsDescription: `Identifiera – verktyget ChemAct hjälper dig identifiera risk och farliga kemikalier per komponent utan krav på kemisk kunskap från användaren.\n\nHantera - verktyget ChemAct hjälper dig hantera de juridiska, kemiska kraven på dina produkter.\n\nSupport – som medlem blir du kontinuerligt uppdaterad om riskabla kemikalier genom frekvent kontakt med vårat support-team och genom våra workshops`,
  contactUsToday: "KONTAKTA OSS IDAG",
  meetTheTeam: "MÖT TEAMET",
  anneDescription:
    "Anne Broström med 30 års erfarenhet som inköpare med produktions och kvalitetsansvar för textila produkter, främst inom Outdoor. Arbetade mycket nära producenter i främst Kina. 2007 började en ny bana som CSR o Miljöansvarig, där erfarenheterna från tidigare år kom till nytta för att stötta leverantörerna i dessa processer. 2011 start av eget företag, för att kunna stötta fler företag i CSR o Miljöfrågor och hur de kan strukturera sig för att klara av de ständigt ökande kraven, som en aktör inom EU. Kuriosa om Anne: talar 5 språk flytande – svenska, finska, engelska, tyska o portugisiska. Ett mål är att även kunna tala kinesiska.",
  stefanDescription:
    "Stefan Posner är en polymer- och textilkemist med över 30 års erfarenhet kring forskning om kemikalier i textilier och andra polymera material. Forskningen sker i samarbete med en variation av organisationer: från små och medelstora företag till stora internationella företag, internationella och nationella myndigheter och akademi i flera internationella och nationella projekt idag och sedan många år tillbaka.",
  lenaDescription: "TODO",
  feesMemberships: "AVGIFTER & MEDLEMSSKAP",
  whatIsIncluded: "Vad ingår i alla medlemsskap",
  includedDescription: `- Support från teamet\n- 24/7h tillgång till verktyget`,
  costUpTo: `FÖRETAG MED OMSÄTTNING UPP TILL ${TURNOVER_LIMIT}`,
  costUpToPerYear: `${LOWER_YEARLY_COST} PER ÅR`,
  costAbove: `FÖRETAG MED OMSÄTTNING ÖVER ${TURNOVER_LIMIT}`,
  costAbovePerYear: `${HIGHER_YEARLY_COST} PER ÅR`,
  faqTitle: "VÅRA VANLIGAST STÄLLDA FRÅGOR...",
  anotherQuestion: "HAR DU NÅGON ANNAN FRÅGA? KONTAKTA OSS HÄR!",
  introduction: "Introduktion",
  welcomeTitle: "Välkommen till Chemact!",
  welcomeText: `Tack för ditt intresse för ChemAct nätverket!\n\nSom medlem i ChemAct får du följande support:\n\n - Verktyget ChemAct hjälper dig identifiera risk och farliga kemikalier per komponent utan krav på kemisk kunskap från användaren.\n - Verktyget ChemAct hjälper dig hantera de juridiska, kemiska kraven på dina produkter.\n - Du blir kontinuerligt uppdaterad om riskabla kemikalier genom frekvent kontakt med vårat support-team och genom våra workshops.\n\nVad händer nu?\nVi kommer kontakta dig och sätta upp ett Teams möte där vi introducerar ChemAct verktyget och nätverket.`,
  welcomeRegards: "Vänliga hälsningar,\n\nChemAct-teamet",
  "log in": "Logga in",
  email: "Email",
  password: "Lösenord",
  forgotPassword: "Glömt lösenord",
  loginHelp: "Hjälp med inloggning",
  myProfile: "Min profil",
  featuredNews: "Presenterade nyheter",
  eventsTitle: "Evenemang",
  messages: "Meddelanden",
  accessTool: "ANVÄND VERKTYGET",
  readMore: "Läs mer",
  title: "Titel",
  phone: "Telefon",
  country: "Land",
  memberSince: "Medlem sedan",
  cancel: "Avbryt",
  edit: "Redigera",
  notAvailable: "Ej tillgänglig",
  new: "nya",
  signUpHere: "Registrera dig här",
  enterDetails: "Fyll i dina uppgifter nedan",
  inviteOther:
    "Om du önskar bjuda in kollegor, fyll i deras namn och email-addresser här",
  sendLarge: "SKICKA",
  libraryDescription:
    "Hitta och kom åt PDF-dokument relaterade till lagstiftning, riktlinjer, förklarande texter, analyser och mer via vår sökmotor",
  searchLibrary: "Sök i biblioteket...",
  foundNoArticles: "Hittade inga matchande artiklar...",
  supportTitle: "KONTAKTA OSS",
  supportDescription:
    "Kontakta oss närsomhelst. Vi återkopplar till dig så fort som möjligt.",
  info: "Info",
  sweden: "Sverige",
  sent: "Skickat",
  archive: "Arkiverat",
  drafts: "Utkast",
  flagged: "Flaggat",
  inboxTitle: "INKORG",
  sendNewMessage: "SKICKA NYTT MEDDELANDE",
  subject: "Ämne",
  message: "Meddelande",
  category: "Kategori",
  noNews: "Inga nyhetsartiklar ännu, kommer snart",
  noMatchingNews: "Hittade inga matchande nyhetsartiklar...",
  noEvents: "Inga event ännu, kommer snart!",
  searchNews: "Sök bland våra nyheter...",
  noMessagesTitle: "Inga nya meddelanden",
  noMessagesText:
    "Inga nya meddelanden har mottagits. Tryck på läs mer för att besöka din inkorg.",
  noNewsTitle: "Inga nya nyhetsartiklar",
  noNewsText:
    "Inga nya nyhetsartiklar har publicerats. Tryck på läs mer för att besöka nyhetssidan.",
  noEventsTitle: "Inga nya event",
  noEventsText:
    "Inga nya event har publicerats. Tryck på läs mer för att besöka event-sidan.",
  loginError: "Misslyckades att logga in. Fel lösenord eller användarnamn.",
  mailSent:
    "Tack för ditt meddelande. Vi återkopplar till dig så fort som möjligt.",
  noMessages: "Inga mottagna meddelanden ännu",
  username: "Användarnamn",
  memberMeeting: "Medlemsmöte",
  memberMeetingPresentation: "Presentation från medlemsmöte.",
  explanations: "Förklaringar av begrepp",
  factSheetMembranes: "Faktablad - membran",
  workshop: "Workshop",
  workshopPresentation: "Presentation från workshop",
  workshopAgenda: "Agenda för workshop",
  workshopSpreadsheet: "Kalkylblad från workshop",
};
