import React, { useState, useEffect } from "react";
import { navigate } from "gatsby";
import useApi from "../api/useApi";

const SessionContext = React.createContext();

const SessionProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loginError, setLoginError] = useState(false);

  const { postLogin, postLogout, getSessionStatus } = useApi();

  const clearLoginError = () => setLoginError(false);

  const login = async (email, password) => {
    const { data: didLogin, error } = await postLogin(email, password);
    setIsLoggedIn(didLogin);
    if (didLogin) {
      navigate("/member");
    } else {
      setLoginError(true);
    }
  };

  const logout = async () => {
    const { data: didLogout, error } = await postLogout();
    setIsLoggedIn(false);
    navigate("/");
  };

  useEffect(() => {
    const updateLoginStatus = async () => {
      const { data: sessionIsValid, error } = await getSessionStatus();
      if (sessionIsValid && !isLoggedIn) {
        setIsLoggedIn(true);
        navigate("/member");
      }
      if (!sessionIsValid) {
        setIsLoggedIn(false);
        navigate("/");
      }
    };

    updateLoginStatus();
  }, []);

  return (
    <SessionContext.Provider
      value={{ isLoggedIn, login, logout, loginError, clearLoginError }}
    >
      {children}
    </SessionContext.Provider>
  );
};

export { SessionContext };
export default SessionProvider;
