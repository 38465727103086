import logo from '../../assets/images/logo.svg';

const events = [
    {
        id: 'member_meeting_2021_12_07',
        date: new Date('2021-12-07'),
        title: 'Member meeting',
        text: 'Presentation from member meeting.',
        image: logo,
        url: 'https://firebasestorage.googleapis.com/v0/b/vote-games.appspot.com/o/ChemAct%20medlemsmo%CC%88te%207%20december%202021.pdf?alt=media&token=6968a489-4df1-4e05-a08f-d86a6ccde513'
    },
    {
        id: 'member_meeting_2021_10_09',
        date: new Date('2021-10-09'),
        title: 'Member meeting',
        text: 'Presentation from member meeting',
        image: logo,
        url: 'https://firebasestorage.googleapis.com/v0/b/vote-games.appspot.com/o/ChemAct%20medlemsmo%CC%88te%209%20oktober%202021.pdf?alt=media&token=b01f52d6-a4a1-4bb5-a911-00dd775dd7ee'
    }
]

export const fetchEvents = () => events;