const KEYS = {
    LAST_LOGIN: 'LAST_LOGIN',
    LOCALE: 'LOCALE'
};

const set = (key, value) => localStorage.setItem(key, JSON.stringify(value));

// TODO: Add try catch ?
const get = (key, defaultValue) => {
    const rawValue = localStorage.getItem(key);
    return rawValue ? JSON.parse(rawValue) : defaultValue;
};

export const setLastLoggedIn = () => set(KEYS.LAST_LOGIN, Date.now());

export const getLastLoggedIn = () => get(KEYS.LAST_LOGIN, Date.now());

export const saveLocale = (locale) => set(KEYS.LOCALE, locale);

export const getLocale = (defaultLocale) => get(KEYS.LOCALE, defaultLocale);