export const TURNOVER_LIMIT = '500MSEK';
export const LOWER_YEARLY_COST = '40K SEK';
export const HIGHER_YEARLY_COST = '60K SEK';

export const TOOL_URL = process.env.GATSBY_TOOL_URL;

export const LIBRARY_CATEGORIES = {
    CHEMICAL_TESTING: 'Chemical testing',
    INTERNATIONAL_CHEMICALS_LEGISLATION: 'International Chemicals legislation',
    EXPLANATORY: 'Explanatory about textile chemicals',
    CHEMICALS_MANAGEMENT: 'Chemicals managment'
};

export const SUPPLIER_ROLE = '4';

export const MAIL_TYPES = {
    SUPPORT: 'support',
    INFO: 'info'
};