import { getNews } from './mockApi'; // TODO: Replace

import { postLogin, postLogout, getSessionStatus } from './domains/login';
import { createMessage, sendMessage, markMessageRead, archiveMessage, deleteMessage, fetchMessages, fetchRecipients } from './domains/messages';
import { fetchProfile, updateProfile, getProfileImage, uploadProfileImage } from './domains/profile';
import { fetchEvents } from './domains/event';

const useApi = () => {
    const fetchNews = async () => {
        return []; //getNews(); // TODO: Replace
    };

    return {
        postLogin,
        postLogout,
        getSessionStatus,
        fetchNews,
        fetchEvents,
        createMessage,
        sendMessage,
        markMessageRead,
        archiveMessage,
        deleteMessage,
        fetchMessages,
        fetchRecipients,
        fetchProfile,
        updateProfile,
        getProfileImage,
        uploadProfileImage
    };
};

export default useApi;